// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-artist-list-js": () => import("./../src/pages/artist-list.js" /* webpackChunkName: "component---src-pages-artist-list-js" */),
  "component---src-pages-bear-files-js": () => import("./../src/pages/bear-files.js" /* webpackChunkName: "component---src-pages-bear-files-js" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-svg-defs-js": () => import("./../src/pages/svg-defs.js" /* webpackChunkName: "component---src-pages-svg-defs-js" */),
  "component---src-pages-theme-js": () => import("./../src/pages/theme.js" /* webpackChunkName: "component---src-pages-theme-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

