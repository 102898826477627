export default {
  colors: {
    text: "#333",
    background: "#FFF",
    primary: "#114B5F",
    secondary: "#1A936F",
    accent: "#D9481C",
    muted: "#C6DABF",
    gray4: "#E3E8E3",
    gray6: "#919191"
  },
  fonts: {
    body: "Maven Pro, sans-serif",
    heading: "Maven Pro, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
}
